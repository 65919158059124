<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>Бюджет</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>{{ $t("app.links.budget_requests") }}<budget-forms-list :curFormSelect="'/form01-123'"/></span>
            <forms-download-reprt
                :progress="progress"
                :isReportUploading="isReportUploading"
                :form="form"
                :guListLen="guListLen"
                @checkSignatories="checkSignatories"
                @downloadRep="downloadRep"
                @downloadBatchReports="downloadBatchReports"
            ></forms-download-reprt>
        </div>
        <div class="filter-container">
            <div class="left-content">
                <budget-header ref="budgetHeader" :form="form" @chgData="changeHeader" :yearReq="true" :openDisabled="openDisabled" @setProgress="setProgress"></budget-header>
            </div>
            <div class="right-content">
                <div class="filter-actions filter-actions-flex">
                    <c-budget-forms-copy-data
                        v-if="!(progress < 100) && variantAttribute && (header.year < header.cur_year + 2)"
                        :budgetForm="budgetForm"
                        :header="header"
                        :isLoad="isLoad"
                        @keyPress="keyPress"
                    />
                    <b-button variant="primary" @click="addItem" v-if="variantAttribute" :disabled="isLoad"><i class="icon icon-plus-circle"></i> Добавить</b-button>
                    <b-button variant="success" @click="prepareForSave" :disabled="isLoad || !variantAttribute">Сохранить</b-button>
                </div>
            </div>
        </div>

        <breadcrumbs-filter :header="{...header, formName: this.formName, spfName: this.spfName}" :data-type-filter="dataTypeFilter"
                            :variant-name="variantName" @openFilterByRef="openFilterByRef"/>
        <b-progress
                variant="success"
                v-show="progress < 100 && progress > 0"
                height="10px"
                :value="progress"
                striped
                animated
        ></b-progress>

        <div class="table-container">
            <b-table
                :fields="tableFields"
                :items="budgetForm"
                responsive="true"
                bordered
                head-variant="light"
                no-border-collapse
            >
                <template #top-row="data">
                    <td class="td-numbering"></td>
                    <td class="td-numbering table-success">1</td>
                    <td class="td-numbering table-danger">2</td>
                    <td class="td-numbering table-info">3</td>
                    <td class="td-numbering table-primary">4</td>
                    <td class="td-numbering table-warning">5</td>
                    <td class="td-numbering table-success">6</td>
                    <td class="td-numbering table-danger">7</td>
                    <td class="td-numbering table-primary">8</td>
                    <td class="td-numbering table-info">9</td>
                    <td class="td-numbering table-primary">10</td>
                    <td class="td-numbering"></td>
                </template>
                 <template #head(action)>
                    <div class="text-center">
                        <b-form-checkbox
                            v-model="selectAll"
                            @change="e => setIsAllDelete(e)"
                            :value="true"
                            :unchecked-value="false" />
                    </div>
                </template>
                <template #head(more)>
                    <div class="text-center">
                        <i
                            title="Удалить выбранные записи"
                            class="icon icon-close table-all-remove"
                            @click="deleteItemWithAttachedFiles(`Удалить ${selectAll ? 'все' : 'выбранные'} записи?`)"/>
                    </div>
                </template>
                <template #cell(action)="data">
                    <b-form-checkbox
                        v-model="data.item.itemToDelete"
                        @input="e => { if (!e) selectAll = false; }"
                        :value="true"
                        :unchecked-value="false" />
                </template>
                <template #cell(comm_service)="data">
                    <div
                        class="column-width-100"
                        :class="{ 'error': data.item.checkForDuplicates }">
                        <b-form-select v-if="variantAttribute"
                                       size="sm"
                                       class="w-200px"
                                       v-model="data.item.comm_service"
                                       @change="checkDoubles()"
                                       :options="getDictServices(dictServices, data.item)"
                        >
                        </b-form-select>
                        <div v-else>{{ data.value.name_ru }}</div>
                        <template v-if="data.item.checkForDuplicates">
                            <i class="icon icon-danger"></i>
                            <div class="pop-up">
                                <p class="red-text">Внимание! Данные не соответствуют контролю: </p>
                                <p>Такая запись уже в базе существует</p>
                            </div>
                        </template>
                    </div>
                </template>
                <template #cell(comm_object)="data">
                    <b-form-select v-if="variantAttribute"
                                   size="sm"
                                   class="w-200px"
                                   v-model="data.item.comm_object"
                                   @change="checkDoubles()"
                                   :options="dictObjects"
                    >
                    </b-form-select>
                    <div v-else>{{ data.value.name_ru }}</div>
                </template>
                <template #cell(rate)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.rate"
                                  @change="v => data.item.rate = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixedVldtn(data.item, 'rate', data.item.rate, 2)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(tariff)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.tariff"
                                  @change="v => data.item.tariff = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixedVldtn(data.item, 'tariff', data.item.tariff, 2)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(power)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.power"
                                  @change="v => data.item.power = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixedVldtn(data.item, 'power', data.item.power, 2)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(correction_factors)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.correction_factors"
                                  @change="v => data.item.correction_factors = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\-?\\d{0,9}$')"
                                  @blur="inputFixedVldtn(data.item, 'correction_factors', data.item.correction_factors, 2)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell()="data">
                    <div class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(note)="data">
                    <div v-if="variantAttribute" :class="{ 'error': ((data.item.note && data.item.note.length > 100)
                    || (data.item.correction_factors !== 0 && data.item.note.trim().length === 0)) }">
                        <b-form-input
                            class="text-right"
                            style="min-width: 90%"
                            :value="data.item.note"
                            @change="v => data.item.note = v"
                            @keyup.enter.exact="keyup13">
                        </b-form-input>
                        <template v-if="(data.item.note && data.item.note.length > 100)">
                            <i class="icon icon-danger"></i>
                            <div class="pop-up">
                                <p class="red-text">Внимание! Данные не соответствуют контролю: </p>
                                <p>Ограничение 100 символов</p>
                            </div>
                        </template>
                        <template v-else-if="(data.item.correction_factors !== 0 && data.item.note.trim().length === 0)">
                            <i class="icon icon-danger"></i>
                            <div class="pop-up">
                                <p class="red-text">Внимание! Данные не соответствуют контролю: </p>
                                <p>Обязательное поля для заполнения</p>
                            </div>
                        </template>
                    </div>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(files)="data">
                    <span
                        class="blue pointer underline"
                        @click="openModalRowFilesByRowId(data.item.id, false)"
                    >({{data.item.files}})</span>
                </template>
                <template #cell(more)="data">
                    <b-dropdown v-if="variantAttribute" id="dropdown-dropleft" dropleft class="more">
                        <template v-slot:button-content>
                            <i class="icon icon-more"></i>
                        </template>
                        <template>
                            <b-dropdown-item @click="openModalRowFilesByRowId(data.item.id, true)">
                                Добавить файл(ы)
                            </b-dropdown-item>
                            <b-dropdown-item v-if="variantAttribute" @click="deleteItemWithAttachedFiles('Удалить запись?', data.item, data.index)">
                                Удалить
                            </b-dropdown-item>
                        </template>
                    </b-dropdown>
                </template>
                <template #bottom-row="data">
                    <td class="text-right" colspan="8">ИТОГО</td>
                    <td><div class="text-right">{{ $n(total) }}</div></td>
                    <td colspan="3"></td>
                </template>
            </b-table>
        </div>
        <div class="table-add" v-if="variantAttribute" :disabled="isLoad">
            <span @click="addItem"><i class="icon icon-plus-circle"></i> Добавить</span>
        </div>
        <files-updown :header="header"
                      :variant-attribute="variantAttribute"
                      :load="load"
                      @getFiles="getFiles"
                      @getNewFiles="getNewFiles($event)"
                      @delFile="delFile($event)"
                      ref="fileUpdown"
        ></files-updown>
        <modal-attach ref="modalAttach"
                      :row-files-input="row_files"
                      :header="header"
                      :is-add="isAdd"
                      :variant-attribute="variantAttribute"
                      @toggleIsAdd="toggleIsAdd($event)"
                      @fileUpload="fileUpload"/>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BudgetHeader from '@/modules/budget-request/budget-header-new.vue';
import FilesUpdown from '@/modules/budget-request/FilesUpdown';
import BudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import BreadcrumbsFilter from '@/modules/budget-request/components/breadcrumbs-filter.vue';
import ModalAttach from '@/modules/budget-request/components/modal-attach.vue';

import { Ax } from '@/utils';
// import BudgetAttachFile from "./components/budget-attach-file";
import FormsHandlerMixin1 from "./mixins/forms-handler-mixin-1";
import CBudgetFormsCopyData from './components/budget-forms-copy-data.vue';
import FormsDownloadReprt from "./components/forms-download-reprt.vue";

export default {
    name: 'Form01_151',
    components: { BudgetHeader, FilesUpdown, BudgetFormsList, FormsDownloadReprt, BreadcrumbsFilter, ModalAttach, CBudgetFormsCopyData },
    mixins: [FormsHandlerMixin1],
    data() {
        return {
            form: {
                code: '01-151',
                name_ru: 'Расчет расходов воды на горячую и холодную воду, канализацию и газ',
                name_kk: 'Ыстық және суық суға, кәрiз бен газға арналған судын шығыстарын Есептеу'
            },
            tableFields: [
                {
                    key: 'action',
                    label: ' '
                },
                {
                    key: 'comm_service',
                    label: 'Наименование'
                },
                {
                    key: 'comm_object',
                    label: 'Объект'
                },
                {
                    key: 'rate',
                    label: 'Норма в натуральном выражении'
                },
                {
                    key: 'tariff',
                    label: 'Тариф'
                },
                {
                    key: 'rate_norm',
                    label: 'Норма в денежном выражении гр.3 х гр.4'
                },
                {
                    key: 'power',
                    label: 'Количество единиц мощности'
                },
                {
                    key: 'correction_factors',
                    label: 'Сумма расходов на поправочные значения (+/- тыс.тг)'
                },
                {
                    key: 'total',
                    label: 'Сумма расходов (гр.5 х гр.6)/1000'
                },
                {
                    key: 'note',
                    label: 'Примечания'
                },
                {
                    key: 'files',
                    label: 'Файлы'
                },
                {
                    key: 'more',
                    label: ''
                }
            ],
            dataTypeFilter: null,
            variantAttribute: null,
            variantName: null,
            budgetForm: [],
            header: null,
            dictServices: [],
            dictObjects: [],
            dictTariffs: [],
            files: null,
            load: false,
            openDisabled: false,
            isLoad: false,
            row_files: [],
            isAdd: false,
            rowId: false,
            newRowStartId: -1,
        };
    },
    async mounted() {
        await this.loadDictServices();
        await this.loadDictObjects();
  //      await this.loadDictTariffs();
    },
    methods: {
        openModalRowFilesByRowId(rowId, isAdd) {
            this.row_files = this.budgetForm.find(b => b.id === rowId)['row_files'];
            this.$refs.modalAttach.openModalRowAddFilesByRowId(rowId);
            this.rowId = rowId;
            this.isAdd = isAdd;
        },
        toggleIsAdd(return_object) {
            const curBudgetForm = this.budgetForm.find(b => b.id === this.rowId)
            curBudgetForm['row_files'] = return_object['row_files']
            curBudgetForm['files'] = return_object['num_attach_files']
            this.isAdd = return_object['isAdd'];
        },
        fileUpload(rowId) {
            this.$refs.fileUpdown.openModalFileUpload();
            this.$refs.fileUpdown.setRowId(rowId);
        },
        getNewFiles(files) {
            this.budgetForm.forEach(item => {
                let num_attach_files = 0;
                item['row_files'] = [...item['row_files'], ...files];
                item['row_files'].forEach((file) => {
                    if (file.row_id === item['id']) {
                        num_attach_files += 1;
                    }
                });
                item['files'] = num_attach_files;
            });
            this.$refs.modalAttach.addNewFiles(files);
        },
        delFile(fileId) {
            this.budgetForm.forEach(item => {
                item['row_files'].forEach((file, index) => {
                    if (file.file_id === fileId) {
                        item['row_files'].splice(index, 1);
                    }
                });
                item.files = item['row_files'].filter(i => i.row_id !== null).length;
            });
            this.$refs.modalAttach.delFile(fileId);
        },
        addItem() {
            const that = this;

            const item = { id: this.newRowStartId };
            this.newRowStartId--;
            this.itemUpdate(item);

            Object.defineProperty(item, 'rate_norm', {
                get: function () {
                    const rateNorm = that.number(item.rate) * that.number(item.tariff);
                    return parseFloat((Math.round(rateNorm * 100) / 100).toFixed(2));
                }
            });
            Object.defineProperty(item, 'total', {
                get: function () {
                    const total = (that.number(item.rate_norm) * that.number(item.power) / 1000) + parseFloat(item.correction_factors);
                    return parseFloat((Math.round(total * 100) / 100).toFixed(2));
                }
            });
            this.budgetForm.push(item);
            this.checkDoubles();
        },

        checkDoubles() {
            const firstEntryItm = {};
            this.budgetForm.forEach((itm, idx) => {
                const serviceCode = itm.comm_service ? itm.comm_service.code : 'no code';   
                const objectCode = itm.comm_object ? itm.comm_object.code : 'no code'; 
                if (!firstEntryItm.hasOwnProperty(serviceCode)) {
                    firstEntryItm[serviceCode] = {};
                    firstEntryItm[serviceCode][objectCode] = idx;
                    itm.checkForDuplicates = false;
                } else {
                    if (!firstEntryItm[serviceCode].hasOwnProperty(objectCode)) {
                        firstEntryItm[serviceCode][objectCode] = idx;
                        itm.checkForDuplicates = false;
                    } else {                        
                        itm.checkForDuplicates = true;
                        const firsDoubleIdx = firstEntryItm[serviceCode][objectCode];
                        this.budgetForm[firsDoubleIdx].checkForDuplicates = true;
                    };
                };
            });
        },

        afterDeleted() {
            this.checkDoubles();
        },

        downloadRep() {
            Ax(
                {
                    url: '/api-py/budg_' + this.form.code.replace('-', '_') + '/' + JSON.stringify(this.header),
                    method: 'POST',
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Форма ' + this.form.code + '.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    this.makeToast('danger', 'Ошибка запроса downloadRep()', error.toString());
                }
            );
        },

        async itemUpdate(item) {
            this.$set(item, 'comm_service', null);
            this.$set(item, 'comm_object', null);
            this.$set(item, 'rate', 0);
            this.$set(item, 'tariff', 0);
            this.$set(item, 'rate_norm', 0);
            this.$set(item, 'power', 0);
            this.$set(item, 'correction_factors', 0);
            this.$set(item, 'note', '');
            this.$set(item, 'itemToDelete', false);

            // этот атрибут чтобы найти схожий запись по наименованию
            this.$set(item, 'checkForDuplicates', false);

            this.$set(item, 'files', 0);

            try {
                this.load = true;
                const response = await fetch(`/api-py/get-new-row-files-form/${item.id}/` + JSON.stringify(this.header));
                const emptyRowFiles = await response.json();
                this.$set(item, 'row_files', emptyRowFiles);
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса itemUpdate', error.toString());
            }
            this.load = false;
        },

        getFiles(data) {
            this.files = data;
        },

        getItem(code, list) {
            const item = list.filter(function (row) {
                if (row.value.code === code) { return row; }
            });

            if (item.length > 0) {
                return item[0].value;
            }
            return null;
        }, // возвращает объект по коду с заданного списка

        keyPress: function (event, pattern) {
            // const regex = new RegExp('^[0-9]+$');
            // const regex = new RegExp('^-?\\d*\\d{0,9}$');
            // const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // ввод по заданному паттерну

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        async loadDatas() {
            const that = this;

            this.load = true;
            await this.budgetForm.splice(0);
            let values = [];
            try {
                const response = await fetch('/api-py/get-budget-request-form-with-row-attach-files/' + JSON.stringify(this.header));
                values = await response.json();

                // в этот массив записывается все наименование записей, потом по этому массиву идет проверка
                // если в него есть массив то атрибут checkForDuplicates равен true
                const listCheckDuplicates = [];

                await values.forEach(val => {
                    const item = {
                        id: val.id,
                        comm_service: this.getItem(val.comm_service, this.dictServices),
                        comm_object: this.getItem(val.comm_object, this.dictObjects),
                        tariff: val.tariff,
                        power: val.power,
                        files: val.files,
                        row_files: val.row_files,
                        itemToDelete: false,
                        rate: val.rate
                    };
                    item['checkForDuplicates'] = !!listCheckDuplicates.find(el => el.comm_service === item.comm_service.code && el.comm_object === item.comm_object.code)

                    val.correction_factors ? that.$set(item, 'correction_factors', val.correction_factors)
                        : that.$set(item, 'correction_factors', 0);
                    val.note ? that.$set(item, 'note', val.note)
                        : that.$set(item, 'note', '');
                    // this.defineRate(item);

                    listCheckDuplicates.push({
                        'comm_service': item.comm_service.code,
                        'comm_object': item.comm_object.code
                    });

                    Object.defineProperty(item, 'rate_norm', {
                        get: function () {
                            const rateNorm = that.number(item.rate) * that.number(item.tariff);
                            return parseFloat((Math.round(rateNorm * 100) / 100).toFixed(2));
                        }
                    });
                    Object.defineProperty(item, 'total', {
                        get: function () {
                            const total = (that.number(item.rate_norm) * that.number(item.power) / 1000) + parseFloat(item.correction_factors);
                            return parseFloat((Math.round(total * 100) / 100).toFixed(2));
                        }
                    });

                    this.budgetForm.push(item);
                });
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadDatas', error.toString());
                return;
            }
            this.load = false;
        },

        async loadDictServices() {
            try {
                const response = await fetch('/api-py/dictionary/comm_services/');
                const items = await response.json();
                const now = new Date(); 
                const year = now.toLocaleString("default", { year: "numeric" });
                const month = now.toLocaleString("default", { month: "2-digit" });
                const day = now.toLocaleString("default", { day: "2-digit" });
                const currDate = `${year}-${month}-${day}`
                for (const row of items) {
                    const el = {};
                    this.$set(el, 'value', row);
                    this.$set(el, 'text', row.name_ru);
                    if (!((row.beg_date === null || row.beg_date <= currDate) && (row.end_date === null || row.end_date >= currDate))) {
                        // если запись не актуальна
                        this.$set(el, 'disabled', true);
                    }
                    this.dictServices.push(el);
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadDictServices', error.toString());
            }
        },

        getDictServices(ds, item) {
            const filteredDs = [];
            ds.forEach(itm => {
                if (item.id < 0) { // new record
                    if (!('disabled' in itm) || !itm.disabled) { // запись актуальна
                        filteredDs.push(itm);
                    }
                } else {
                    if (!('disabled' in itm) || !itm.disabled) { // запись актуальна
                        filteredDs.push(itm);
                    } else if (itm.value.code === item.comm_service.code) { // запись не актуальна но сохранена
                        filteredDs.push(itm);
                    }
                }
            })
            return filteredDs
        },

        async loadDictObjects() {
            try {
                const response = await fetch('/api-py/dictionary/comm_objects/');
                const items = await response.json();

                for (const row of items) {
                    const el = {};
                    this.$set(el, 'value', row);
                    this.$set(el, 'text', row.name_ru);
                    if (['1', '2'].includes(row.code)) {
                        this.$set(el, 'disabled', true);
                    }
                    this.dictObjects.push(el);
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadDictObjects', error.toString());
            }
        },

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

        number(value) {
            return (isNaN(parseFloat(value)) ? 0 : parseFloat(value));
        },

        prepareForSave() {
            const values = [];
            let error = false;
            const template = (row) => {
                const item = Object.assign({}, this.header);
                this.$set(item, 'id', row.id);
                this.$set(item, 'comm_service', row.comm_service.code);
                this.$set(item, 'comm_object', row.comm_object.code);
                this.$set(item, 'tariff', parseFloat(row.tariff));
                this.$set(item, 'power', parseFloat(row.power));
                this.$set(item, 'rate', parseFloat(row.rate));
                this.$set(item, 'correction_factors', parseFloat(row.correction_factors));
                this.$set(item, 'note', row.note);
                this.$set(item, 'row_files', row.row_files);
                this.$set(item, 'total', row.total);
                values.push(item);
            }
            for (const row of this.budgetForm) {
                if (!((row.comm_service === null) || (row.comm_object === null))) {
                    if (row.correction_factors === 0 && (row.note === null || row.note.length < 101)) {
                        template(row);
                    } else if (row.correction_factors !== 0 && row.note.trim().length !== 0 && row.note.length < 101) {
                        template(row);
                    } else {
                        error = true;
                    }
                } else {
                    error = true;
                }
            }
            if (error) {
                this.onFieldsValidationFailed();
                return;
            }
            if (this.budgetForm.find(item => item.checkForDuplicates === true)) {
                this.makeToast('danger', 'Предупреждение', 'Данные не соответствуют контролю. Данные не сохранены!');
            }
            else {
                if (values.length && this.variantAttribute) {
                    this.save(values, error);
                }
                else {
                    this.makeToast('warning', 'Сообщение', 'Данные не сохранены');
                }
            }
        }, // подготовка к сохранению

        async save(values, error) {
            this.isLoad = true;
            try {
                const totalToSave = this.totalCalculation(values, 'total')
                this.$set(this.header, 'value', totalToSave);
                const response = await fetch('/api-py/save-brform' + this.form.code + '/' +
                    JSON.stringify(this.header), {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(values)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    // await this.saveTotal();
                    if (this.files.length === 0) {
                        this.makeToast('danger', 'Предупреждение', 'Внимание! Отсутствуют необходимые документы. Пожалуйста, прикрепите недостающие файлы.');
                    }
                    this.makeToast('success', 'Сообщение', 'Данные сохранены');
                    await this.loadDatas();
                } else {
                    await this.loadDatas();
                    throw 'Ошибка сохранения данных. Возможно не все обязательные поля заполнены';
                }
            } catch (e) {
                this.makeToast('danger', 'Предупреждение', e.toString());
            } finally {
                this.isLoad = false;
            }
        }, // сохранение данных

        async saveTotal() {
            this.$set(this.header, 'value', this.total);
            try {
                await fetch('/api-py/save-budget-request-total/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(this.header)
                });
            } catch {
                this.makeToast('danger', 'Предупреждение', 'Ошибка сохранения итога');
            }
        } // сохранение итога
    },
    computed: {
        total() {
            return this.totalCalculation(this.budgetForm, 'total');
        }
    }
};
</script>

<style scoped>
    .filter-actions-flex {
        display: flex;
    }
</style>